import React from "react";

const Hero = () => {
  return (
    <div className=" pb-[100px] lg:pb-[200px] relative px-[20px] ">
      <div className="mt-[100px] flex justify-center items-center">
        <img src={require("../../assets/home/t.png")} alt="" />
      </div>
      {/* <div className=" font-black  text-[40px] lg:text-[50px] xl:text-[88px] leading-[60px] xl:leading-[90px] fontClass3 uppercase ">
        Trained and Experienced
      </div>
      <div className=" font-black  text-[#0C1B33] text-[40px] lg:text-[50px] xl:text-[88px] leading-[60px] xl:leading-[90px] fontClass3 uppercase ">
        Unarmed Security Guards
      </div> */}

      <div className="flex justify-between   lg:px-[100px] pt-[100px] ">
        <div className="pt-[200px] text-end  max-w-[278px]   text-[10px] text-grey leading-[12px]  ">
          <div className="pb-[10px] flex justify-end">
            <img src={require("../../assets/home/Vector 3 (1).png")} alt="" />
          </div>
          Trained for superior situational awareness and conflict de-escalation,
          ATS unarmed guards ensure safety for both our clients and people
          around them
        </div>
        <div
          className="absolute hidden lg:block   left-[30%] "
          style={{ bottom: "0px" }}
        >
          <img
            src={require("../../assets/home/Property 1=Default.png")}
            alt=""
          />
        </div>
        <div className=" max-w-[278px] text-start  text-[10px] text-grey leading-[12px]">
          <div className="pb-[10px]">
            <img src={require("../../assets/home/Vector 3.png")} alt="" />
          </div>
          We tailor our security services to meet the unique needs of each
          client, combining cutting-edge technology with strategic planning to
          provide comprehensive protection and peace of mind
        </div>
      </div>
    </div>
  );
};

export default Hero;
