import React, { useEffect } from "react";
import NavScrollExample from "../Navbar/NavScrollExample";
import WhyUs from "../WhyUS/WhyUs";
import SeviceSlide from "./SeviceSlide";
import TouchIn from "../TouchIn/TouchIn";
import Footer from "../Footer/Footer";
import "./styles.css";
import WhyUs3 from "../WhyUS/WhyUs3";
import Marquee from "react-fast-marquee";
import AutoPlay from "./AutoPlay";

const Service = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const data = [
    {
      name: "Mobile Patrol Services",
    },

    {
      name: "Event Security",
    },
    {
      name: "Management Executive Protection Services",
    },
    {
      name: "CCTV Monitoring and Response",
    },
    {
      name: "Executive Protection Services",
    },
    {
      name: "Access Control Systems Management",
    },
    {
      name: "Fire Watch Services",
    },
    {
      name: "Residential Security Services",
    },
    {
      name: "Corporate Security Solutions",
    },
    {
      name: "Cargo and Container Security",
    },
    {
      name: "Maritime Security for Cargo Ships",
    },
  ];

  return (
    <div>
      <NavScrollExample />
      <div className=" relative  flex flex-col  gap-[50px] lg:gap-[0px] justify-between  lg:flex-row  px-[20px] md:px-[50px] py-[100px] lg:py-[200px] ">
        <div>
          <div className="uppercase max-w-[521px] fontClass3 font-black text-start  text-[40px] md:text-[72px] leading-[40px] md:leading-[74px] text-[#0C1B33]">
            <span className="custom-text text-white">Trained Experienced</span>{" "}
            Unarmed Security Guards
          </div>
          <div className="   absolute bottom-0 hidden lg:flex justify-center items-center left-[30%]">
            <img src={require("../../assets/home/sevice.png")} alt="" />
          </div>
          <div className="mt-[50px]">
            <img src={require("../../assets/home/button (1).png")} alt="" />
          </div>
        </div>
        <div>
          <div className="text-[12px] fontClass leading-[19px] text-start max-w-[434px] pl-[10px]  border-l-[1px] border-l-[#000000]">
            Our guards are trained in advanced communication techniques,
            ensuring clear, concise, and effective exchanges in any situation to
            maintain optimal security coordination.
          </div>

          <div className="text-[12px] mt-[100px] lg:ml-[150px] fontClass leading-[19px] text-start max-w-[277px] pl-[10px]  border-l-[1px] border-l-[#000000]">
            Our guards are trained to remain alert at all times, providing
            top-notch security with keen observation skills to detect and
            respond to any unusual activity swiftly.
          </div>
        </div>
      </div>
      <AutoPlay />

      <WhyUs3 />
      {/* <div className="py-[100px]">
        <div className=" uppercase max-w-[523px] mx-auto fontClass3 font-black text-[40px] sm:text-[56px] leading-[40px] sm:leading-[57px] text-[#0C1B33]">
          What our clients says
        </div>

        <div className="mt-[100px] py-[50px] bgclass">
          <div className="  relative flex justify-center items-center mb-[-70px] z-10">
            <img src={require("../../assets/home/Ellipse 17 (1).png")} alt="" />
          </div>
          <div className="relative">
            <SeviceSlide />
          </div>
        </div>
      </div> */}

      <TouchIn />
      <Footer />
    </div>
  );
};

export default Service;
