import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import SwiperCorefrom from "swiper";
import "./styles.css";

// import required modules
import { Navigation, EffectCards } from "swiper/modules";
SwiperCorefrom.use([Navigation, EffectCards]);

export default function SeviceSlide() {
  const swiperRef = useRef(null);
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;
      const currentIndex = swiper.activeIndex;
      const nextIndex = (currentIndex === swiper.slides.length - 1) ? 0 : currentIndex + 1;
      swiper.slideTo(nextIndex); // Move to the next slide
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;
      const currentIndex = swiper.activeIndex;
      const prevIndex = (currentIndex === 0) ? swiper.slides.length - 1 : currentIndex - 1;
      swiper.slideTo(prevIndex); // Move to the previous slide
    }
  };
  
  const data = [
    {
      name: "D. Enyeart, Retired Major General",
      post: "US Army ",
      text: "Zubair is a trusted and professional individual that we used extensively in Afghanistan. He anticipated the needs of the command with secure intelligence and with safety as a high priority. I know he will succeed in any endeavors that he is involved in. ",
      img: require("../../assets/home/Ellipse 17 (1).png"),
    },
    {
      name: "D. Enyeart, Retired Major General",
      post: "US Army ",
      text: "Zubair is a trusted and professional individual that we used extensively in Afghanistan. He anticipated the needs of the command with secure intelligence and with safety as a high priority. I know he will succeed in any endeavors that he is involved in. ",
      img: require("../../assets/home/Ellipse 17 (1).png"),
    },
    {
      name: "D. Enyeart, Retired Major General",
      post: "US Army ",
      text: "Zubair is a trusted and professional individual that we used extensively in Afghanistan. He anticipated the needs of the command with secure intelligence and with safety as a high priority. I know he will succeed in any endeavors that he is involved in. ",
      img: require("../../assets/home/Ellipse 17 (1).png"),
    },
    {
      name: "D. Enyeart, Retired Major General",
      post: "US Army ",
      text: "Zubair is a trusted and professional individual that we used extensively in Afghanistan. He anticipated the needs of the command with secure intelligence and with safety as a high priority. I know he will succeed in any endeavors that he is involved in. ",
      img: require("../../assets/home/Ellipse 17 (1).png"),
    },
    {
      name: "D. Enyeart, Retired Major General",
      post: "US Army ",
      text: "Zubair is a trusted and professional individual that we used extensively in Afghanistan. He anticipated the needs of the command with secure intelligence and with safety as a high priority. I know he will succeed in any endeavors that he is involved in. ",
      img: require("../../assets/home/Ellipse 17 (1).png"),
    },  {
      name: "D. Enyeart, Retired Major General",
      post: "US Army ",
      text: "Zubair is a trusted and professional individual that we used extensively in Afghanistan. He anticipated the needs of the command with secure intelligence and with safety as a high priority. I know he will succeed in any endeavors that he is involved in. ",
      img: require("../../assets/home/Ellipse 17 (1).png"),
    },
  ];
  return (
    <div className="relative">
      <Swiper
        ref={swiperRef}
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper relative"
        navigation={{
          nextEl: ".next",
          prevEl: ".prev",
        }}
      >
        {data.map((item) => {
          return (
            <SwiperSlide className="flex flex-col justify-center items-center">
              {/* <div className="flex justify-center items-center  ">
                <img src={item.img} alt="" className="absolut " />
              </div> */}
              <div className=" max-w-[722px]  mx-auto shadow-lg py-[50px] px-[20px] ">
                <div className=" mt-[20px] text-[20px] lg:text-[28px] leading-[29px] text-center font-bold text-[#3D3D3D] fontClass3">
                  {item.name}
                </div>
                <div className=" text-[18px] lg:text-[21px] leading-[25px] text-center font-normal text-[#3D3D3D] fontClass">
                  {item.post}
                </div>
                <div className=" mt-[20px]  text-[12px] lg:text-[19px] leading-[23px] text-center font-normal text-[#575756] fontClass max-w-[670px] mx-auto">
                  {item.text}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="next  cursor-pointer" onClick={goNext}>
        {" "}
        <img
          src={require("../../assets/home/Group 12.png")}
          alt=""
          className="absoulte right-[50px] top-[50%] "
        />
      </div>
      <div className="prev cursor-pointer" onClick={goPrev}>
        {" "}
        <img
          src={require("../../assets/home/Group 14.png")}
          alt=""
          className="absoulte left-[50px] top-[50%] "
        />{" "}
      </div>
    </div>
  );
}
