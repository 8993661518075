import logo from "./logo.svg";
import "./App.css";
import NavScrollExample from "./Component/Navbar/NavScrollExample";
import Footer from "./Component/Footer/Footer";
import TouchIn from "./Component/TouchIn/TouchIn";
import WhyUs from "./Component/WhyUS/WhyUs";
import Slide from "./Component/Home/Slide";
import Hero from "./Component/Home/Hero";
import Home from "./Component/Home/Home";
import { Routes, Route } from "react-router-dom";
import Contact from "./Component/ContactUs/Contact";
import AboutUs from "./Component/AboutUs/AboutUs";
import SeviceSlide from "./Component/Service/SeviceSlide";
import Service from "./Component/Service/Service";
function App() {
  return (
    <div className="App">
      <Routes>
        {/* <Route path="/" element={} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/service" element={<Service />} />

        <Route path="/slide" element={<SeviceSlide />} />
      </Routes>
    </div>
  );
}

export default App;
